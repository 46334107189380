import React, { useEffect, useState } from 'react'
import { cat_paw, cat } from 'utils/script.js';
import './style/cat.css';
// Translate
import { useTranslation } from 'react-i18next';
// GA
import ReactGa from 'react-ga'
import { clickDownloadButton } from '../../utils/download';

const Cat = () => {
    const { t } = useTranslation()
    const TABLE_WIDTH = 992;
    const DESKTOP_CAT_SHOW_LIMIT = 10;
    const MOBILE_CAT_SHOW_LIMIT = 50;

    const DESKTOP_PAW_SHOW_LIMIT = 50;
    const MOBILE_PAW_SHOW_LIMIT = 30;
    const SHOW_AGAIN_RANGE = 400;
    const [isShowCatPaw, setIsShowCatPaw] = useState(true)

    const handleScroll = () => {    
      let cHeight = document.documentElement.clientHeight;
      let sHeight = document.documentElement.scrollHeight;
      let sTop = document.documentElement.scrollTop;
      
      // if((sHeight- SHOW_AGAIN_RANGE) <= (cHeight + sTop)) {
      //   document.getElementById("cat_head").classList.remove('cat_close_anim');
      //   document.getElementById("cat_paw").classList.remove('cat_close_anim');
      //   document.getElementById("cat_head").classList.add('cat_watch_show_again_anim');
      //   document.getElementById("cat_paw").classList.add('cat_paw_show_again_anim');
      // }
      let number = window.pageXOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;      
      const catShowScrollNumber = window.innerWidth > TABLE_WIDTH ? DESKTOP_CAT_SHOW_LIMIT : MOBILE_CAT_SHOW_LIMIT;
      const catPawScrollNumber = window.innerWidth > TABLE_WIDTH ? DESKTOP_PAW_SHOW_LIMIT : MOBILE_PAW_SHOW_LIMIT;
      if(number > catShowScrollNumber) {
        document.getElementById("cat_head").classList.add('cat_watch_anim');
      } else {
        document.getElementById("cat_head").classList.remove('cat_watch_anim');
        document.getElementById("cat_head").classList.remove('cat_close_anim');
        document.getElementById("cat_head").classList.remove('cat_watch_show_again_anim');
      }
      
      if(number > catPawScrollNumber) {
        document.getElementById("cat_paw").classList.add('cat_paw_anim');
      } else {
        document.getElementById("cat_paw").classList.remove('cat_paw_anim');
        document.getElementById("cat_paw").classList.remove('cat_close_anim');
        document.getElementById("cat_paw").classList.remove('cat_paw_show_again_anim');
      }
    }

    useEffect(() => {
      if(isShowCatPaw) {
        window.addEventListener('scroll', handleScroll);  
        return () => {
          window.removeEventListener('scroll', handleScroll);
        }    
      } else {
        return
      }
    })

    return(
      <div className="cat">
         <div id="cat_head" className="cat_head" dangerouslySetInnerHTML={{ __html: cat }} />        

         <div id="cat_paw" className="cat_paw">

          <div 
            onClick={() =>{
              document.getElementById("cat_head").classList.add('cat_close_anim');
              document.getElementById("cat_paw").classList.add('cat_close_anim');
              setIsShowCatPaw(false)
            }} 
            className="close_cat_paw">
            <div className="g-right"></div>
          </div>
          <a 
            href="https://bit.ly/2zP8vtG"
            title={t('home.cat_download')}
            target="_blank"
            onClick={() => {
              clickDownloadButton('有按下貓爪')             
            }} dangerouslySetInnerHTML={{ __html: cat_paw }} />
          <span>{t('home.cat_download')}</span>
         </div>
      </div>
    )
}

export default Cat