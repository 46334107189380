import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/auth'

var firebaseConfig = (() => {
  console.log(process.env.NODE_ENV)
  if (process.env.NODE_ENV === 'production') {
    return ({
      apiKey: "AIzaSyCD6HedQDuBXOpDwgdLs48C714oVDBE5J8",
      authDomain: "fluv-prod.firebaseapp.com",
      databaseURL: "https://fluv-prod.firebaseio.com",
      projectId: "fluv-prod",
      storageBucket: "fluv-prod.appspot.com",
      messagingSenderId: "958791800397",
      appId: "1:958791800397:web:9857d397259c62201d2e04",
      measurementId: "G-HFWWL61X37"
    });
  }
  return ({
    apiKey: "AIzaSyDoViOzUbDk3dlUwJKVX0J8BVJYAN-S5qU",
    authDomain: "fluv-dev.firebaseapp.com",
    databaseURL: "https://fluv-dev.firebaseio.com",
    projectId: "fluv-dev",
    storageBucket: "fluv-dev.appspot.com",
    messagingSenderId: "212501929273",
    appId: "1:212501929273:web:dc7997a08049b8e7",
    measurementId: "G-K7XF5564Y7"
  });
})();
firebase.initializeApp(firebaseConfig);