import * as types from 'constants/ActionTypes';


const filtersReducerDefaultState = {
    brand: ["nike", "caprese", "lifestyle"],
    value: { min: 250, max: 950 },
    sortBy: ""
};

const filtersReducer = (state = filtersReducerDefaultState, action) => {
    switch (action.type) {
        case types.FILTER_AREA:
            return {
                ...state,
                brand: action.brand
            };
        case types.FILTER_PET_CATEGORY:
            return {
                ...state,
                color: action.color
            };
        case types.FILTER_SERVICE_CATEGORY:
            return {
                ...state,
                value: {min: action.value.value.min, max: action.value.value.max }
            };
        default:
            return state;
    }
}

export default filtersReducer;