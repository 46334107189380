import * as types from "constants/ActionTypes";
const initialState = {
  detial: {},
  list: [],
  recommendList: [],
  reviews: [],
  allReview: [],
};

const sitterReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_SITTER_LIST:
      return {
        ...state,
        list: action.sitters
      };
    case types.GET_SINGLE_SITTER:
      // console.log('sitterDetail',action.sitterDetail[0])
      return {
        ...state,
        detial: action.sitterDetail[0]
      };
    case types.GET_RECOMMENT_SITTER_LIST:
      return {
        ...state,
        recommendList: action.sitters
      };
    case types.GET_ALL_SITTER_REVIEW: 
      return {
        ...state,
        allReview: action.reviewList
      }
    case types.GET_PROFILE_REVIEWS:
      return {
        ...state,
        reviews: action.reviews        
      }
    default:
      return state;
  }
};
export default sitterReducer;