const delCookie = (name) => {
  var exp = new Date();
  exp.setTime (exp.getTime() - 1);
  var cval = getCookie (name);
  document.cookie = name + "=" + cval + "; expires="+ exp.toGMTString();
}

const getCookie = (cname) => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
};

export { delCookie, getCookie };