
import ReactGa from 'react-ga'

export const clickDownloadButton = (string) => {
  const u = navigator.userAgent;
  const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
  const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
  if(isAndroid) {
    ReactGa.event({
      category: 'App',
      action: 'Download'
    })
    return
  }
  if(isiOS) {
    ReactGa.event({
      category: 'App',
      action: 'Download'
    })
    return
  }
  ReactGa.event({
    category: 'App',
    action: 'Download'
  })
}