import { combineReducers } from 'redux';

// Import custom components
import sitterReducer from './sitters';
import serviceReducer from './services';
import filtersReducer from './filters';
import _globalReducer from './_global';

const rootReducer = combineReducers({
  sitter:sitterReducer,
  service:serviceReducer,
  filters: filtersReducer,
  _global: _globalReducer
});

export default rootReducer